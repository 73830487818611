import React from 'react';
import viantLogo from './images/viantLogo.png'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* Header text here&nbsp;&nbsp;|&nbsp;&nbsp;More text here */}
      </header>
      <body className="App-body">
        <img src={viantLogo} alt='Viant Solar' />
        <br />
        <br />
      </body>
      <footer className="App-footer">
        
				{/* <div className='site-footer'>
					
						© 2020 Viant Solar, LLC. All rights reserved.
					
				</div> */}

      </footer>
    </div>
  );
}

export default App;
